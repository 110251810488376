import { useTranslation } from "react-i18next";
import React from "react";

export default function InputText({
                                    type,
                                    label,
                                    isShowLabel = true,
                                    isShowError = true,
                                    isRequired,
                                    id,
                                    errors,
                                    warning,
                                    warningRegex,
                                    input,
                                    defaultValue,
                                    className = "",
                                    isDisabled = false,
                                    placeholder = ''
                                  }) {
  const { t } = useTranslation();

  return (
      <div className={className}>
          {
              isShowLabel && (
                  <label
                      htmlFor={id}
                      className="tracking-wide text-white"
                  >
                      {label} {isRequired && <span className="text-red-500">*</span>}
                  </label>
              )
          }

        {type === "textarea" ? (
            <textarea
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                cols="30" rows="6"
                className={`mt-3 w-full border border-white/20 bg-transparent px-4 py-3 text-white/70 outline-none focus:ring-1 focus:ring-primary ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={t("app.common.placeholder", {
                  field: label.toLowerCase(),
                })}
                {...input}
            />
        ) : (
            <input
                type={type}
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`mt-3 w-full border border-white/20 bg-transparent px-4 py-3 text-white/70 outline-none focus:ring-1 focus:ring-primary ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={label ? t("app.common.placeholder", {
                  field: label.toLowerCase(),
                }) : t("app.common.placeholder", {
                  field: placeholder.toLowerCase(),
                })}
                {...input}
            />
        )}

          {
              isShowError && (
                  <>
                      {errors && errors.type === "required" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning ? warning : t("app.warning.description", { field: label })}
                          </p>
                      )}
                      {errors && errors.type === "validate" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning ? warning : (errors.message ? errors.message : t("app.warning.description", { field: label }))}
                          </p>
                      )}
                      {errors && errors.type === "pattern" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warningRegex
                                  ? warningRegex
                                  : t("app.warning.description_regex", { field: label })}
                          </p>
                      )}
                  </>
              )
          }


      </div>
  );
}
