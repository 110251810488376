import React, {useEffect, useState} from "react";
import { Route, Routes } from "react-router";
import Homepage from "./pages/homepage";
import {useTranslation} from "react-i18next";
import StoryblokClient from 'storyblok-js-client';
import Loading from "../components/common/loading";
import ProjectPage from "./pages/project-pages";

function Main() {


    const storyBlok = new StoryblokClient({
        accessToken: '4KhSlz6VKwz1HO02aL4NJwtt',
    });
    const { i18n } = useTranslation();
    let language = i18n.language.includes('en') ? 'en' : 'it';


    const [siteConfig, setSiteConfig] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setSiteConfig(false);
    }, [language]);

    useEffect(() => {
        if(!siteConfig){
            storyBlok.get(`cdn/stories/config`, {"language": language})
                .then(response => {
                    setSiteConfig(response.data.story);
                    setLoading(false);
                }).catch(error => {
                console.error('Error fetching story:', error);
            });
        }
        // eslint-disable-next-line
    }, [storyBlok]);


    if(isLoading){
        return (<><Loading /></>);
    }


    return (
        <Routes>
            <Route path="/" element={<Homepage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
            <Route path="/project/:page" element={<ProjectPage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
        </Routes>
  );
}

export default Main;
