import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";
import {useTranslation} from "react-i18next";
import Rellax from "rellax";

function ProjectPage({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);

    const [isLoadingPage, setLoadingPage] = useState(true);

    let navigate = useNavigate();

    let {page}  = useParams();



    useEffect(() => {
        setLoadingPage(true); // Assuming this sets a loading state
        storyBlok.get("cdn/stories/project/"+page, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
                window.scrollTo(0, 0)
            }).catch(error => {
                //navigate("/")
        });

    }, [page, storyBlok, navigate, language]);


    useEffect(() => {
        if(!isLoadingPage){
            const rellax = new Rellax('.rellax');
            return () => {
                if (rellax && typeof rellax.destroy === 'function') {
                    rellax.destroy();
                }
            };

        }
    }, [isLoadingPage]);


    if(isLoadingPage){
        return (<><Loading /></>);
    }


  return (
      <Layout siteConfig={siteConfig}>
          <main className="relative overflow-hidden">
              <Header siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'project_header') ?? false} />
              <Project storyBlok={storyBlok}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'project_detail') ?? false} />
              <NextProject storyBlok={storyBlok}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'nex_prject') ?? false} />











          </main>

      </Layout>
  );
}


const Header = ({componentDetail}) => {


    return (
        <section id="home" className="sticky top-0 flex min-h-screen items-center bg-gradient-to-b from-black/10 via-black/20 to-black media-h:min-h-[60vh] xl:min-h-screen media-h:xl:min-h-screen">
            <img data-rellax-speed="3" data-rellax-xs-speed="2" data-rellax-mobile-speed="2"
                 className="rellax absolute inset-0 h-full -z-10 w-full object-cover	" src={componentDetail.backgraound} alt="project cover" width="2000" height="1333"/>
            <div className="absolute inset-0 bg-black opacity-50 -z-10" />
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t -z-10 from-black pt-[25%]" />

            <div
                className="relative z-10 mx-auto flex h-full max-w-7xl items-center px-6 lg:px-12 xl:px-6 2xl:px-0">
                <h1 data-rellax-speed="-1" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                    className="rellax text-center text-7xl font-black text-white sm:text-8xl lg:text-9xl xl:leading-tight">{componentDetail.title}</h1>
            </div>
        </section>
    )
}

const NextProject = ({componentDetail}) => {
    const { t } = useTranslation();


    return (
        <section id="approach" className="relative z-10 bg-black py-20 md:sticky md:top-12">
            <Link to={componentDetail.link.url} className="block bg-gradient-to-b from-transparent to-white/10 py-32" style={{backgroundColor: componentDetail.image  }}>

                <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                    <div className="flex">
                        <div className="w-full">
                                  <span className="mx-auto block h-max w-max rounded-full border border-white/40 px-2 py-1 text-xs tracking-wider text-white">{t("app.next_project")}</span>
                            <h2 className="text-center text-7xl font-bold text-white xl:text-8xl">{componentDetail.title}</h2>
                        </div>
                    </div>
                </div>
            </Link>
        </section>
    )
}


const Project = ({storyBlok, componentDetail}) => {


    return (
        <>
            <section id="introduction" className="relative top-0 z-10 bg-black pb-20 pt-20 md:sticky">
                <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                    <div className="flex">
                        <div className="w-full">
                            <div data-rellax-speed="-2" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                                 className="rellax flex flex-wrap items-center gap-6">
                                <h2 className="text-7xl font-bold text-white xl:text-8xl">{componentDetail.title}</h2>
                                <span
                                    className="h-max rounded-full border border-white/40 px-2 py-1 text-xs tracking-wider text-white">01</span>
                            </div>
                            <div className="mt-12 ml-auto text-lg font-light text-white sm:text-2xl md:w-2/3 lg:mt-0 lg:w-1/2 xl:leading-normal" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(componentDetail.description ?? [])}} />

                        </div>
                    </div>
                </div>
            </section>

            <section id="overview" className="relative z-10 bg-black pb-20 lg:pt-20">
                <div className="xl:px-6">
                    <div className="grid grid-cols-2 gap-3 md:gap-6">
                        <div
                            className="relative before:absolute before:inset-0 before:origin-top before:bg-gradient-to-t before:from-black/5 before:opacity-50  hover:before:origin-bottom hover:before:scale-y-0">
                            <img className="transition duration-500" src={componentDetail.img_1}
                                 alt="project" width="1380" height="1380"/>
                        </div>
                        <div data-rellax-speed="-2" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                             className="rellax relative before:absolute before:inset-0 before:origin-top before:bg-gradient-to-t before:from-black/5 before:opacity-50 before:transition before:duration-500 hover:before:origin-bottom hover:before:scale-y-0">
                            <img className="h-full w-full object-cover transition duration-500"
                                 src={componentDetail.img_2} alt="project cover" width="2000" height="1333"/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="approach" className="relative z-10 bg-black pb-20 pt-20 md:sticky md:top-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                    <div className="flex">
                        <div className="w-full">
                            <div className="flex flex-wrap items-center gap-6">
                                <h2 className="text-7xl font-bold text-white xl:text-8xl">{componentDetail.approach_title} </h2>
                                <span
                                    className="h-max rounded-full border border-white/40 px-2 py-1 text-xs tracking-wider text-white">02</span>
                            </div>
                            <div
                                className="mt-24 ml-auto text-lg font-light text-white sm:text-2xl md:w-2/3 lg:w-1/2 xl:leading-normal"  dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(componentDetail.approach_description ?? [])}}/>

                        </div>
                    </div>
                </div>
            </section>


            <section id="result" className="relative z-10 bg-black pb-20 md:top-0 lg:pt-20">
                <div className="space-y-3 md:space-y-6 xl:px-6">
                    <div className="mt-6 grid grid-cols-2 gap-3 md:gap-6">
                        <div
                            className="relative before:absolute before:inset-0 before:origin-top before:bg-gradient-to-t before:from-black/5 before:opacity-50  before:transition before:duration-500 hover:before:origin-bottom hover:before:scale-y-0">
                            <img className="transition duration-500" src={componentDetail.img_3}
                                 alt="project cover" width="1380" height="1380"/>
                        </div>
                        <div
                            className="relative before:absolute before:inset-0 before:origin-top before:bg-gradient-to-t before:from-black/5 before:opacity-50  before:transition before:duration-500 hover:before:origin-bottom hover:before:scale-y-0">
                            <img className="h-full w-full object-cover transition duration-500"
                                 src={componentDetail.img_4} alt="project cover" width="2000" height="1333"/>
                        </div>
                    </div>
                    <div className="relative">
                        <div
                            className="absolute inset-0 z-10 m-auto hidden lg:h-max lg:w-max lg:-translate-y-56 xl:block">
                            <div className="mx-auto max-w-7xl px-6 lg:-translate-y-96 lg:px-12 xl:px-6 2xl:px-0">
                                <div data-rellax-speed="-2" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                                     className="rellax -mt-96">
                                    <div className="flex flex-wrap items-center gap-6">
                                        <h2 className="text-7xl font-bold text-white sm:text-8xl xl:text-9xl">{componentDetail.scale_title}</h2>
                                        <span
                                            className="h-max rounded-full border border-white/40 px-2 py-1 text-xs tracking-wider text-white">03</span>
                                    </div>
                                    <div
                                        className="mt-12 ml-auto text-lg font-light text-white sm:text-2xl md:w-2/3 lg:w-2/5 xl:leading-normal" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(componentDetail.scale_description ?? [])}} />

                                </div>
                            </div>
                        </div>
                        <img className=" h-full w-full object-cover" src={componentDetail.img_5} alt="cover"
                             width="1800" height="1200"/>

                    </div>
                </div>
            </section>


        </>

    )
}





export default ProjectPage;
