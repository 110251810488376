import { NavLink} from "react-router-dom";
import logo from "../../resource/images/logo.png";
import {useTranslation} from "react-i18next";
import InputText from "../form/input-text";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useState} from "react";
import {emailRegex} from "../../constants/regex";
import InputSubmit from "../form/input-submit";
import { HashLink } from 'react-router-hash-link';


function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export function Footer({siteConfig, isDark}) {


    const [exception, setException] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isSendLoading, setSendLoading] = useState(false);
    const { i18n } = useTranslation();


    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();


    const onSubmit = async (data) => {
        data['locale'] = i18n.language.includes('en') ? 'en' : 'it';
        setSuccess(false);
        setException(false);
        setSendLoading(true);




        const mail = {
            service_id: 'service_g9fvoqn',
            template_id: 'template_h9bc1pt',
            user_id: 'YmQY_gImhv-5TK2IQ',
            template_params: {
                fullname: data.firstname+' '+data.lastname,
                email: data.email,
                message: data.message
            }

        };


        axios.post('https://api.emailjs.com/api/v1.0/email/send', mail)
            .then(function (response) {
                setSuccess(true);
            }).catch(function (error) {
            setException(true);
        }).finally(function () {
            setSendLoading(false);
        });

    };



    return (
        <>
            <section id="contact" className="relative z-10 bg-gradient-to-b from-black via-black/80 to-black pt-32 backdrop-blur-3xl lg:pb-32 lg:pt-0">
                <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                    <div className="flex flex-wrap items-center gap-6">
                        <h2 className="text-7xl font-bold text-white xl:text-8xl">{t("app.contact_title")}</h2>
                    </div>
                    <div className="mt-24">
                        <div className="grid gap-6 border-t border-white/30 pt-24 lg:grid-cols-3 lg:gap-24">
                            <div className="lg:col-span-2">
                                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto space-y-8 md:w-3/4">
                                    <div className="grid gap-8 sm:grid-cols-2 sm:gap-4">
                                        <InputText
                                            id={"firstname"}
                                            type={"text"}
                                            errors={errors.firstname}
                                            input={{ ...register("firstname", { required: true }) }}
                                            label={t("app.form.firstname")}
                                        />

                                        <InputText
                                            id={"lastname"}
                                            type={"text"}
                                            errors={errors.lastname}
                                            input={{ ...register("lastname", { required: true }) }}
                                            label={t("app.form.lastname")}
                                        />
                                    </div>

                                    <InputText
                                        id={"email"}
                                        type={"text"}
                                        errors={errors.email}
                                        input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                                        label={t("app.form.company_mail")}
                                    />

                                    <InputText
                                        id={"message"}
                                        type={"textarea"}
                                        errors={errors.message}
                                        input={{ ...register("message", { required: true }) }}
                                        label={t("app.form.message")}
                                    />


                                    {
                                        exception && (
                                            <div className="mt-5">
                                                <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
                                                    <div className="flex">
                                                        <div className="ml-3">
                                                            <p className={`text-sm text-red-700`}>
                                                                {t("app.form.error")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }


                                    {
                                        success && (
                                            <div className={'mt-5 mb-5'}>
                                                <div className={`bg-green-50 border-l-4 border-green-400 p-4`}>
                                                    <div className="flex">
                                                        <div className="ml-3">
                                                            <p className={`text-sm text-green-700`}>
                                                                {t("app.form.success")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <InputSubmit
                                        isLoading={isSendLoading}
                                        isFullWith={true}
                                        label={t("app.form.btn_confirm")}
                                    />
                                </form>
                            </div>
                            <div className="mt-8 border border-white/30 p-8 sm:p-12">
                                <div>
                                    <h3 className="text-xs font-light uppercase tracking-widest text-white">{t("app.form.address")}</h3>
                                    <p className="mt-4 text-white">Via Carlo Tenca, 22 - 20124 Milano MI, Italy</p>
                                </div>
                                <div className="mt-16">
                                    <h3 className="text-xs font-light uppercase tracking-widest text-white">{t("app.form.contactInfo")}</h3>
                                    <ul className="relative z-20 mt-4 space-y-2 font-light text-white">
                                        <li>
                                            <a rel="noreferrer" href="tel:+393737773231">{t("app.form.phone")} ------ +39 373 777 3231</a>
                                        </li>
                                        <li>
                                            <a rel="noreferrer" href="mailto:admin@skemasoft.com">E-mail ------- admin@skemasoft.com</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-16">
                                    <h3 className="text-xs font-light uppercase tracking-widest text-white">{t("app.form.followUs")}</h3>
                                    <ul className="relative z-20 mt-4 space-y-2 text-sm font-light text-white">
                                        <li>
                                            <a rel="noreferrer" href={siteConfig.content.Linkedin.url} target="_blank">001 ------ Linkedin</a>
                                        </li>
                                        <li>
                                            <a rel="noreferrer" href={siteConfig.content.instagram.url} target="_blank">002 ------ Instagram</a>
                                        </li>
                                        <li>
                                            <a rel="noreferrer" href={siteConfig.content.facebook.url} target="_blank">003 ------ Facebook</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="relative bg-black pt-32 backdrop-opacity-0">
                <div className="mx-auto max-w-7xl px-6 pb-12 lg:px-12 xl:px-6 2xl:px-0">
                    <div className="mt-12 md:mt-16 lg:mt-24">
                        <div className="space-y-8 md:space-y-12">
                            <HashLink to="/" className="text-2xl font-light tracking-widest text-white">
                                <img className="h-8 w-auto brightness-200" src={logo} alt="logo mark"
                                     width="100" height="100"/>
                            </HashLink>



                            <nav>
                                <ul className="flex flex-wrap gap-6 text-sm uppercase tracking-wider text-white">
                                    <li>
                                        <HashLink to="/#home" rel="noreferrer">{t("menu.home")}</HashLink>
                                    </li>
                                    <li>
                                        <HashLink to="/#work" rel="noreferrer">{t("menu.work")}</HashLink>
                                    </li>
                                    <li>
                                        <HashLink to="/#services" rel="noreferrer">{t("menu.services")}</HashLink>
                                    </li>
                                    <li>
                                        <HashLink  to="/#about" className="block w-max" rel="noreferrer">{t("menu.about_us")}</HashLink>
                                    </li>
                                    <li>
                                        <HashLink to="/#contact" rel="noreferrer">{t("menu.contact")}</HashLink>
                                    </li>
                                </ul>
                            </nav>


                            <div className="flex flex-wrap justify-between gap-3">
                                <span className="text-sm text-white/50">Copyright &copy; {new Date().getFullYear()} Skemasoft SRL. All rights reserved</span>
                                <LanguageSwitch />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    )
}
const LanguageSwitch = () => {

    const { i18n } = useTranslation();


    const setLanguage = (locale) => {
        i18n.changeLanguage(locale);
    };


    return (
        <div className="inline-flex relative -mt-2 md:mt-0">

            <NavLink
                onClick={() => {setLanguage('it')}}
                to="#contact"
                className={classNames(
                    i18n.language.includes('it') ?  "text-white" : "text-white/50",
                    "relative py-1.5  text-sm  before:transition before:duration-300 hover:before:scale-y-100 hover:before:scale-x-125 hover:before:bg-white/10"
                )}
            >
                <span className="relative mr-1 pr-1 border-r border-white">it</span>
            </NavLink>

            <NavLink
                onClick={() => {setLanguage('en')}}
                to="#contact"
                className={classNames(
                    i18n.language.includes('en') ?  "text-white" : "text-white/50",
                    "relative py-1.5  text-sm  before:transition before:duration-300 hover:before:scale-y-100 hover:before:scale-x-125 hover:before:bg-white/10"
                )}
            >
                <span className="relative mr-2">en</span>
            </NavLink>


        </div>
    )
}
