import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import Rellax from "rellax";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}
const Homepage = ({siteConfig, storyBlok, language}) => {

    const [pageDetail, setPageDetail] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        storyBlok.get(`cdn/stories/homepage`, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoading(false);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });
    }, [storyBlok, language]);


    useEffect(() => {
        if(!isLoading){
            const rellax = new Rellax('.rellax');
            return () => {
                if (rellax && typeof rellax.destroy === 'function') {
                    rellax.destroy();
                }
            };

        }
    }, [isLoading]);

    if(isLoading){
        return (<><Loading /></>);
    }

    return (

        <Layout siteConfig={siteConfig}>
            <main className="background relative">
                <Header siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'header') ?? false} />
                <OurWork siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'projects') ?? false} />
                <OurServices siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'services') ?? false} />
                <AboutUs siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'about_us') ?? false} />
            </main>
        </Layout>

  );
}



const Header = ({siteConfig, componentDetail}) => {

    const { t } = useTranslation();

    return(
        <section id="home" className="relative flex min-h-screen items-center">
            <div aria-hidden="true" className="absolute inset-0 z-[1] bg-gradient-to-b from-indigo-950/30 via-black/20 to-black" />
            <img src={componentDetail.background} className="fixed inset-0 h-full w-full object-cover"
                 alt="woman in dark" width="4160" height="6240"/>
            <div className="relative z-10 mx-auto max-w-7xl px-6 pb-40 pt-60 lg:px-12 xl:px-6 2xl:px-0">
                <div className="pb-12 media-h:md:pb-32 media-h:lg:pb-12 xl:pb-12">
                    <h1 data-rellax-speed="-3" data-rellax-xs-speed="0" data-rellax-mobile-speed="0" className="rellax text-6xl font-bold text-white sm:text-8xl md:text-9xl xl:leading-tight">{componentDetail.title}</h1>
                </div>
                <div>
                    <div className="ml-auto md:w-2/3 md:pt-12 lg:w-1/2">
                        <p className="mb-20 text-lg font-light text-white sm:text-2xl xl:leading-normal">
                            {componentDetail.description}
                        </p>
                        <HashLink data-rellax-speed="1" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                           to="#work"
                           className="rellax relative inline-block py-1.5 text-white before:absolute before:inset-0 before:origin-bottom before:scale-y-[.03] before:bg-white/60 before:transition before:duration-300 hover:before:scale-y-100 hover:before:scale-x-125 hover:before:bg-white/10">
                            <span className="relative">{t("menu.our_work")}</span>
                        </HashLink>
                    </div>
                </div>
                <div data-rellax-speed="-5" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                     className="rellax relative mt-16 ml-auto w-max md:mt-32 md:ml-0 xl:-mt-16">
                    <span className="text-xs font-light uppercase tracking-widest text-white">{t("app.form.followUs")}</span>
                    <ul className="relative z-20 mt-4 space-y-2 text-sm font-light text-white">
                        <li>
                            <a href={siteConfig.content.Linkedin.url} target="_blank" rel="noreferrer">001 ------ Linkedin</a>
                        </li>
                        <li>
                            <a href={siteConfig.content.instagram.url} target="_blank" rel="noreferrer">002 ------ Instagram</a>
                        </li>
                        <li>
                            <a href={siteConfig.content.facebook.url} target="_blank" rel="noreferrer">003 ------ Facebook</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

const OurWork = ({componentDetail}) => {


    return(
        <section id="work" className="relative z-10 bg-black pb-20 lg:pt-0">
            <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 xl:pb-96 2xl:px-0">
                <div data-rellax-speed="-3" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                     className="rellax flex flex-wrap items-center gap-6">
                    <h2 className="text-7xl font-bold text-white xl:text-8xl">{componentDetail.title}</h2>
                </div>
                <div className="relative mt-20 gap-20 gap-x-6 space-y-20 sm:grid sm:grid-cols-2 sm:space-y-0 md:mt-72 lg:mt-60">

                    {
                        (componentDetail.projects ?? []).map((project, index) => (
                            <Link to={project.link.url} data-rellax-speed={project.rellax_speed} data-rellax-xs-speed={project.xs_speed} data-rellax-mobile-speed={project.mobile_speed} data-rellax-tablet-speed={project.tablet_speed}
                                  className={classNames('rellax group', project.class)}>
                                <div
                                    className="relative before:absolute before:inset-0 before:origin-top before:bg-gradient-to-t before:from-black/5 before:opacity-50 before:backdrop-grayscale before:transition before:duration-500 group-hover:before:origin-bottom group-hover:before:scale-y-0">
                                    <img className="transition duration-500" src={project.image} alt="gild cover" width="2000" height="1333"/>
                                </div>
                                <div className="flex items-center justify-between p-4">
                                    <h3 className="text-2xl font-normal text-white">{project.title}</h3>
                                    <span
                                        className="h-max rounded-full border border-white/30 px-2 py-1 text-xs tracking-wider text-white">{project.date}</span>
                                </div>
                            </Link>
                        ))
                    }



                </div>
            </div>
        </section>
    )
}

const OurServices = ({componentDetail}) => {


    return(
        <section id="services" className="relative bg-black pb-20 pt-32 md:pb-0 lg:pb-0 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                <div className="flex flex-wrap items-center gap-6">
                    <h2 className="text-7xl font-bold text-white xl:text-8xl">{componentDetail.title}</h2>
                </div>
                <div className="mt-24">
                    <div className="">
                        <div className="grid gap-12 sm:grid-cols-2 xl:grid-cols-3">

                            {
                                (componentDetail?.services ?? []).map((service, index) => (
                                    <div>
                                        <div className="group border-b border-white/30 pb-8">
                                            <div className="flex flex-col gap-4 divide-y divide-white/30">
                                                <span className="inline-block text-white/60">00{index +1}</span>
                                                <h3 className="bg-black pt-6 text-3xl text-white">{service.title}</h3>
                                            </div>
                                            <div
                                                className="mt-0 overflow-hidden transition-all duration-500 group-hover:mt-8">
                                                <p className="max-h-0 font-light text-white/70 transition-all duration-500 group-hover:max-h-36 md:text-xl">{service.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }




                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}


const AboutUs = ({componentDetail}) => {


    return(
        <section id="about" className="relative z-10 bg-black pb-20 pt-32 md:pb-0 md:pt-0 lg:pb-0">
            <div className="mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
                <div data-rellax-speed="-0.4" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                     className="rellax flex flex-wrap items-center gap-6">
                    <h2 className="text-7xl font-bold text-white xl:text-8xl">{componentDetail.title}</h2>
                </div>
                <div className="mt-24 md:mt-72">
                    <div className="grid gap-6">
                        <div className="grid md:grid-cols-3">
                            <div className="overflow-hidden md:col-span-2">
                                <img src={componentDetail.image} alt="unnamed duo" width="1500" height="1000"/>
                            </div>
                        </div>
                        <div data-rellax-speed="1" data-rellax-xs-speed="0" data-rellax-mobile-speed="0"
                             data-rellax-tablet-speed="0.5" className="rellax ml-auto md:w-3/5 lg:w-2/5">
                            <p className="mt-12 text-2xl font-light text-white">{componentDetail.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Homepage;
