import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { HashLink } from 'react-router-hash-link';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}



export function Navigation({siteConfig, isFixed = false, isDark = false }) {

    const { t } = useTranslation();

    return (
        <header className={classNames(
            isDark ?  "" : "",
            isFixed ?  "" : "",
            "fixed top-0 z-20 w-full")}>
            <nav className="2lg:px-12 mx-auto max-w-7xl px-6 py-12 lg:px-12 xl:px-6 2xl:px-0">
                <div className="flex items-center justify-between">
                    <NavLink to="/" className="text-2xl font-light tracking-widest text-white">
                        <img src={siteConfig?.content?.header_logo?.filename}  className="h-10 w-auto" alt="" />
                    </NavLink>

                    <HashLink
                        to="#contact"
                        className="relative py-1.5 text-white before:absolute before:inset-0 before:origin-bottom before:scale-y-[.03] before:bg-white/60 before:transition before:duration-300 hover:before:scale-y-100 hover:before:scale-x-125 hover:before:bg-white/10"
                    >
                        <span className="relative">{t("menu.contact_us")}</span>
                    </HashLink>

                </div>
            </nav>
        </header>
    )
}
