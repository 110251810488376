import { useTranslation } from "react-i18next";

const InputSubmit = ({
  isLoading,
  label,
  LoadingLabel,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <button
      className={` ml-auto flex h-12 w-auto items-center overflow-hidden  px-5  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none border border-transparent rounded-md shadow-sm text-sm font-medium flex items-center`}
      disabled
    >
      <svg
        className="animate-spin w-4 h-4 fill-current shrink-0"
        viewBox="0 0 16 16"
      >
        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
      </svg>
      <span className="ml-2 relative uppercase tracking-wide text-black ">
        {LoadingLabel ? LoadingLabel : t("app.form.loading")}
      </span>
    </button>
  ) : (

      <button type="submit"
              disabled={disabled}
              className="group ml-auto flex h-12 w-auto items-center overflow-hidden bg-white px-5 transition-all duration-300 hover:bg-white/50">
        <span className="relative uppercase tracking-wide text-black group-hover:text-white">{label}</span>
      </button>

  );
};
export default InputSubmit;
