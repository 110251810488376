import React from 'react';
import {Navigation} from "./navigation";
import {Footer} from "./footer";

const Layout = ({siteConfig, children, isDark = true, isFixed = false}) => {

    return (
        <>
            <Navigation siteConfig={siteConfig} isFixed={isFixed} isDark={isDark} />
                {children}
            <Footer siteConfig={siteConfig} isDark={isDark} />
        </>
    );
}


export default Layout;
